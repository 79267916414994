<template>  
  <v-container>
      <v-toolbar
        color="text-h6 grey lighten-2"
        flat
      >
        <v-icon>mdi-filter</v-icon>
        <v-toolbar-title>
          기본 필터 ( {{filtered_stocks.toLocaleString()}} 종목)
        </v-toolbar-title>
      </v-toolbar>

      <v-row class="pa-4" justify="space-between">
        <!-- 시장 -->
        <v-col cols="12" sm="4">   
          <v-subheader class="pl-0 font-weight-bold">시장</v-subheader>         
          <v-radio-group @change="event_sender" v-model="market" row>   
            <v-radio label="ANY" value="ANY" class="d-flex ma-2"></v-radio>
            <v-radio label="KOSPI" value="KOSPI" class="d-flex ma-2"></v-radio>
            <v-radio label="KOSDAQ" value="KOSDAQ" class="d-flex ma-2"></v-radio>  
            <v-radio label="KOSDAQ GLOBAL" value="KOSDAQ GLOBAL" class="d-flex ma-2"></v-radio>          
            <v-radio label="KONEX" value="KONEX" class="d-flex ma-2"></v-radio>
          </v-radio-group> 
        </v-col>

        <!-- 시총 -->
        <v-col cols="12" sm="4">  
            <v-subheader class="pl-0 font-weight-bold">시총</v-subheader>
            <v-slider @change="event_sender" v-model="market_cap" min="1" max="100" :thumb-size="32" thumb-label="always"></v-slider>
            하위 {{ market_cap }} % 이하
        </v-col>
        
        <!-- 업종 -->
        <v-col cols="12" sm="4">
            <v-subheader class="pl-0 font-weight-bold">업종</v-subheader>
            <v-combobox @change="event_sender" v-model="sector" :items="sector_options" multiple clearable small-chips>
            </v-combobox>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
  import axios from 'axios';

  export default {    
    // props: ['copyMarket', 'copyMarketCap', 'copySector'],
    // props: ['v_market', 'v_market_cap', 'v_sector'],
    data () {
      return {
        sector_options: ['비금속', '운송장비·부품', '숙박·음식', '섬유·의류', '의료정밀', '통신장비', '기계·장비', '건설업', '음식료·담배', '비금속광물', '통신업',
                        '컴퓨터서비스', '기타금융','서비스업','일반전기전자','전기전자','반도체','전기가스업','유통','정보기기','운수창고업','화학','음식료품','기타제조업',
                        '방송서비스','보험','유통업','금속','건설','증권','철강금속','금융','오락·문화','운송','기계','기타서비스','기타제조','운수장비','전기·가스·수도',
                        '인터넷','종이·목재','디지털컨텐츠','제약','광업','통신서비스','IT부품','소프트웨어','농업, 임업 및 어업','은행','출판·매체복제','의약품','섬유의복',
                        '종이목재','의료·정밀기기',
                        ],
        filtered_stocks: 0,        
      }
    },

    computed: {
      ...mapState({
        marketFromStore: 'market',
        marketcapFromStore: 'market_cap',
        sectorFromStore: 'sector',
      }),
      market: {
        get(){
          return this.marketFromStore
        },
        set(newMarket){
          this.$store.state.market = newMarket
        } 
      },
      market_cap: {
        get(){
          return this.marketcapFromStore
        },
        set(newMarketCap){
          this.$store.state.market_cap = newMarketCap
        } 
      },
      sector: {
        get(){
          return this.sectorFromStore
        },
        set(newSector){
          this.$store.state.sector = newSector
        } 
      },
    },

    created() {
      // 초기화      
      this.setMarket({'market': 'ANY', 'market_cap': 1000, 'sector': ['전체']});
      this.event_sender();
    },

    methods: {
      ...mapMutations(['debug', 'setMarket']),

      event_sender() {        
        if (this.sector.length == 0) this.sector = ['전체'];        
        if (this.sector.length > 1 && this.sector.includes('전체')) this.sector.shift();
        var text = {};
        text['market'] = this.market; 
        text['market_cap'] = this.market_cap; 
        text['sector'] = this.sector;
        // this.setMarket(text);
        var vm = this;
        const jwt = localStorage.getItem('jwt');
        const headers = {"Authorization": "jwt "+ jwt};
        
        //axios.post('/api/quant/filter/', text)
        axios.post('/api/quant/filter/', text, {headers})
          .then(function(res) {
            vm.filtered_stocks = res.data;
          })
          .catch(function (err) {
            vm.snackbar = true;
            vm.snackColor = 'error';
            vm.snackText = err;
          });
      }
    }
  }
</script>