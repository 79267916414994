<template>
<div>
  <v-container>
      <v-toolbar
        color="text-h6 grey lighten-2"
        flat
      >
        <v-icon>mdi-filter</v-icon>
        <v-toolbar-title>지표 필터 ({{ new_filters.length }}) </v-toolbar-title>
      </v-toolbar>

    <v-row align="start">
      <v-col align="start" :cols="cols1">
        <v-treeview
          v-model="v_filters"
          :items="treeItems"
          selectable
          return-object
          item-text="text"
          @input="putFilter"
          hoverable
          expand-icon="mdi-chevron-down"
          on-icon="mdi-bookmark"
          off-icon="mdi-bookmark-outline"
          indeterminate-icon="mdi-bookmark-minus"
        >
         <template v-slot:label="{ item }">
          <v-hover v-slot:default="{ hover }">
            <div>
              <span>{{item.text}}</span>
              <v-spacer>
              <span style="color:blue;" v-if="hover">{{item.comment}}</span>
              </v-spacer>
            </div>
          </v-hover>
        </template>
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col :cols="cols1">
        <template>
          <br>
          <v-subheader align="start">지표별 필터링 조건(최소,최대값)을 수정하세요.</v-subheader>
          <v-data-table
            :headers="filterHeaders"
            :items="new_filters"
            :items-per-page="100"
            :hide-default-footer=true
            class="elevation-1"  
            no-data-text="필터링할 지표를 선택하세요."    
          >
            <template v-slot:item.actions="{ item }">
              <v-icon 
                small
                @click="deleteFilterItem(item)"
              >
                mdi-close
              </v-icon>
            </template>

            <template v-slot:item.min="props">
              <v-edit-dialog
              :return-value.sync="props.item.min"
              persistent
              large
              @save="saveFilter"
              cancel-text="취소"
              save-text="저장"
              >
              <div>{{ props.item.min }}</div>
              <template v-slot:input>
                  <div class="mt-4 text-h6">
                  최소값
                  </div>
                  <v-text-field
                  v-model="props.item.min"
                  label="입력"
                  single-line
                  ></v-text-field>
              </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.max="props">
              <v-edit-dialog
              :return-value.sync="props.item.max"
              large
              persistent
              @save="saveFilter"
              cancel-text="취소"
              save-text="저장"        
              >
              <div>{{ props.item.max }}</div>
              <template v-slot:input>
                  <div class="mt-4 text-h6">
                  최대값
                  </div>
                  <v-text-field                
                  v-model="props.item.max"
                  label="입력"
                  single-line
                  ></v-text-field>
              </template>
              </v-edit-dialog>
            </template>
          </v-data-table>

        </template>
      </v-col>
    </v-row>
    
    <br>
    <!-- 매수전략 트리뷰 -->
    <v-toolbar
    color="light-blue lighten-4"
    flat
    >
      <v-icon>mdi-bullseye-arrow</v-icon>
      <v-toolbar-title>매수 전략 ({{ new_rules.length }}) </v-toolbar-title>
    </v-toolbar>

    <v-row align="start">
      <v-col align="start" :cols="cols1">
        <v-treeview
          v-model="v_rules"
          :items="treeItems"
          selectable
          return-object
          item-text="text"
          @input="putRule"
          hoverable
          expand-icon="mdi-chevron-down"
          on-icon="mdi-bookmark"
          off-icon="mdi-bookmark-outline"
          indeterminate-icon="mdi-bookmark-minus"
        >
          <template v-slot:label="{ item }">
            <v-hover v-slot:default="{ hover }">
              <div>
                <span>{{item.text}}</span>
                <v-spacer>
                <span style="color:blue;" v-if="hover">{{item.comment}}</span>
                </v-spacer>
              </div>
            </v-hover>
          </template>
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col :cols="cols1">
        <template>
          <br>
          <v-subheader align="start">지표별 비중은 수정 가능합니다. (기본: 균등배분)</v-subheader>
          <v-data-table
            :headers="ruleHeaders"
            :items="new_rules"
            :items-per-page="100"
            :hide-default-footer=true
            class="elevation-1"  
            no-data-text="매수전략 지표를 선택하세요." 
            dense   
          >
            <template v-slot:item.actions="{ item }">
              <v-icon 
                small
                @click="deleteRuleItem(item)"
              >
                mdi-close
              </v-icon>
            </template>

            <template v-slot:item.ratio="props">
              <v-edit-dialog
              :return-value.sync="props.item.ratio"
              persistent
              large
              @save="saveRule"
              cancel-text="취소"
              save-text="저장"
              >
              <div>{{ props.item.ratio }}</div>
              <template v-slot:input>
                  <div class="mt-4 text-h6">
                  비중
                  </div>
                  <v-text-field
                  v-model="props.item.ratio"
                  label="입력"
                  single-line
                  ></v-text-field>
              </template>
              </v-edit-dialog>
            </template>            
          </v-data-table>

        </template>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
      :color="snackColor"          
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    props: ['copyFilters', 'copyRules'],
    name: 'FilterTreeV02',
    data: function () {
      return {
        filterHeaders: [
          {
            text: '항목',
            align: 'start',
            sortable: false,
            value: 'category',
          },
          { text: '지표', value: 'text' },
          { text: '망소/망대', value: 'flag' },
          { text: '최소값', value: 'min' },
          { text: '최대값', value: 'max' },
          { text: '삭제', value: 'actions', sortable: false },
        ],
        ruleHeaders: [
          {
            text: '항목',
            align: 'start',
            sortable: false,
            value: 'category',
          },
          { text: '지표', value: 'text' },
          { text: '망소/망대', value: 'flag' },
          { text: '비중', value: 'ratio' },
          { text: '삭제', value: 'actions', sortable: false },
        ],
        v_rules: [],
        v_filters: [],
        selectable: true,
        deletedIndex: null,
        treeItems: [          
          {
            id: 1,
            text: '지표',
            children: [
            {
              id: 2,
              text: '주가지수',
              children: [
                { id: 3, disabled: true, key: '1', category: '주가지수', text: '자사주비중(%)', value: 'ownshare_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '자기주식수 * 100 / 상장주식수' },
                { id: 4, key: '2', category: '주가지수', text: '주식수증가율(%)', value: 'share_growth_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '현재상장주식수 * 100 / 1년전 상장 주식수, 상장 주식수는 수정주가에 맞추어 재조정' },
                { id: 5, key: '3', category: '주가지수', text: '1개월등락율(%)', value: 'month1_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(현재수정주가 - 1개월전수정주가) * 100 / 1개월전수정주가)' },
                { id: 6, key: '4', category: '주가지수', text: '3개월등락율(%)', value: 'month3_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(현재수정주가 - 3개월전수정주가) * 100 / 3개월전수정주가)' },
                { id: 7, key: '5', category: '주가지수', text: '6개월등락율(%)', value: 'month6_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(현재수정주가 - 6개월전수정주가) * 100 / 6개월전수정주가)' },
                { id: 8, key: '6', category: '주가지수', text: '9개월등락율(%)', value: 'month9_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(현재수정주가 - 9개월전수정주가) * 100 / 9개월전수정주가)' },
                { id: 9, key: '7', category: '주가지수', text: '12개월등락율(%)', value: 'month12_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(현재수정주가 - 12개월전수정주가) * 100 / 12개월전수정주가)' },
                { id: 10, key: '8', category: '주가지수', text: '거래대금(억)5일평균', value: 'day5_avg', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 5일간 평균 거래대금'},
                { id: 11, key: '9', category: '주가지수', text: '신고가율(%)', value: 'new_high_rate', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '당일종가 * 100 / 52주신고가,  현재주가와 신고가와 근접시 100에 수렴, 100이면 당일주가가 신고가' },
                { id: 12, key: '10', category: '주가지수', text: '신저가율(%)', value: 'new_low_rate', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(당일종가 * 100 / 52주신저가) - 100,  현재주가와 신저가와 근접시 0에 수렴, 0이면 당일주가가 신저가' }
              ],          
            },
            {
              id: 13,
              text: '수익성',
              children: [
                { id: 14, key: '1', category: '수익성', text: 'GPA', value: 'gpa', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '매출총이익 * 100 / 자산, 높을수록 자산대비 이익의 효율성이 좋음, 최근 4분기 연환산 데이터 적용'},
                { id: 15, key: '2', category: '수익성', text: 'GPM', value: 'gpm', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '매출총이익 * 100 / 매출액, 높을수록 해자가 있음, 최근 4분기 연환산 데이터 적용' },
                { id: 16, key: '3', category: '수익성', text: '분기GPM', value: 'qgpm', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기매출총이익 * 100 / 분기매출액, 높을수록 해자가 있음' },
                { id: 17, key: '4', category: '수익성', text: 'OPM', value: 'opm', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '영업이익 * 100 / 매출액, 높을수록 해자가 있음, 최근 4분기 연환산 데이터 적용' },
                { id: 18, key: '5', category: '수익성', text: '분기OPM', value: 'qopm', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기영업이익 * 100 / 분기매출액, 높을수록 해자가 있음' },
                { id: 19, key: '6', category: '수익성', text: 'NPM', value: 'npm', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '지배주주순이익 * 100 / 매출액, 높을수록 해자가 있음, 최근 4분기 연환산 데이터 적용' },
                { id: 20, key: '7', category: '수익성', text: '분기NPM', value: 'qnpm', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기지배주주순이익 * 100 / 분기매출액, 높을수록 해자가 있음' },
                { id: 21, key: '8', category: '수익성', text: 'ROE', value: 'roe', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '지배주주순이익 * 100 / 지배주주소유자기자본, 높을수록 이익의 질이 좋음, 최근 4분기 연환산 데이터 적용' },
                { id: 22, key: '9', category: '수익성', text: 'ROA', value: 'roa', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '지배주주순이익 * 100 / 총자산, 높을수록 이익의 질이 좋음, 최근 4분기 연환산 데이터 적용' },
                { id: 23, key: '10', category: '수익성', text: 'FCFF', value: 'fcff', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '영업활동현금흐름 - 설비투자, 높을수록 이익의 질이 좋음, 최근 4분기 연환산 데이터 적용' }
              ],          
            },            
            {
              id: 24,
              text: '수급',
              children: [
                { id: 25, key: '1', category: '수급', text: '1개월기관(%)', value: 'sd_institution20', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '1개월 기관순매수대금*100/시가총액, 월간 기관의 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 기관의 매수강도가 강함' },
                { id: 26, key: '2', category: '수급', text: '1개월외인(%)', value: 'sd_foreigner20', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '1개월 외인순매수대금*100/시가총액,월간 외인의 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 외인의 매수강도가 강함'},
                { id: 27, key: '3', category: '수급', text: '1개월수급(%)', value: 'sd20', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(기관+외인) 1개월 순매수대금*100/시가총액,월간 기관 외인의 합산 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 기관과 외인의 매수강도가 강함' },
                { id: 28, key: '4', category: '수급', text: '3개월기관(%)', value: 'sd_institution60', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '3개월 기관순매수대금*100/시가총액, 월간 기관의 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 기관의 매수강도가 강함'},
                { id: 29, key: '5', category: '수급', text: '3개월외인(%)', value: 'sd_foreigner60', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '3개월 외인순매수대금*100/시가총액,월간 외인의 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 외인의 매수강도가 강함' },
                { id: 30, key: '6', category: '수급', text: '3개월수급(%)', value: 'sd60', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(기관+외인) 3개월 순매수대금*100/시가총액,월간 기관 외인의 합산 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 기관과 외인의 매수강도가 강함' },
                { id: 31, key: '7', category: '수급', text: '6개월기관(%)', value: 'sd_institution120', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '6개월 기관순매수대금*100/시가총액, 월간 기관의 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 기관의 매수강도가 강함' },
                { id: 32, key: '8', category: '수급', text: '6개월외인(%)', value: 'sd_foreigner120', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '6개월 외인순매수대금*100/시가총액,월간 외인의 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 외인의 매수강도가 강함' },
                { id: 33, key: '9', category: '수급', text: '6개월수급(%)', value: 'sd120', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(기관+외인) 6개월 순매수대금*100/시가총액,월간 기관 외인의 합산 순매수가 시가 총액대비 얼마나 매수했는지를 알수 있고 높을 수록 기관과 외인의 매수강도가 강함'  }
              ],          
            },
            {
              id: 34,
              text: '밸류(가치)',
              children: [
                { id: 35, key: '1', category: '밸류', text: 'PFCR', value: 'pfcr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/FCFF, FCFF=(영업활동현금흐름-설비투자), 수치가 낮다는것은 시총대비 현금흐름이 좋음, 최근 4분기 연환산데이터 적용'},   
                { id: 36, key: '2', category: '밸류', text: '분기PFCR', value: 'qpfcr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/최근분기FCFF, 분기FCFF=(분기영업활동현금흐름-분기설비투자)*4, 수치가 낮다는것은 시총대비 현금흐름이 좋음'},
                { id: 37, key: '3', category: '밸류', text: 'PCR', value: 'pcr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/영업현금흐름, 낮을수록 현금흐름대비 주가가 저렴하다고 볼수 있음, 최근 4분기 연환산데이터 적용'},
                { id: 38, key: '4', category: '밸류', text: '분기PCR', value: 'qpcr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/최근분기영업현금흐름*4, 낮을수록 현금흐름대비 주가가 저렴하다고 볼수 있음'},
                { id: 39, key: '5', category: '밸류', text: 'PSR', value: 'psr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/매출액, 낮을수록 매출액대비 주가가 저렴하다고 볼수 있음, 최근 4분기 연환산데이터 적용'},
                { id: 40, key: '6', category: '밸류', text: '분기PSR', value: 'qpsr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/최근분기매출액*4, 낮을수록 매출액대비 주가가 저렴하다고 볼수 있음'},
                { id: 41, key: '7', category: '밸류', text: 'PEG', value: 'peg', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '발표PER/EPS증가율, 미래성장대비 밸류에이션으로 수차가 낮을수록 매력적임' },
                { id: 42, key: '8', category: '밸류', text: '분기PEG', value: 'qpeg', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '발표분기PER/최근분기EPS증가율, 미래성장대비 밸류에이션으로 수차가 낮을수록 매력적임'},
                { id: 43, key: '9', category: '밸류', text: 'PBR', value: 'pbr', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/지배주주소유자기자본, 낮을수록 자산가치대비 주가가 저렴한편에 속함'},
                { id: 44, key: '10', category: '밸류', text: 'PER', value: 'per', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/지배주주순이익, 낮을수록 수익가치대비 주가가 저렴한편에 속함, 최근 4분기 연환산 데이터 적용'},
                { id: 45, key: '11', category: '밸류', text: '분기PER', value: 'qper', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/분기지배주주순이익*4, 낮을수록 수익가치대비 주가가 저렴한편에 속함'},
                { disabled: true, id: 46, key: '12', category: '밸류', text: 'EV/EBITDA', value: 'evevitda', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(시총+순차입금)/(영업이익+감가상각비), 낮을수록 가치대비 저평가, 최근 4분기 연환산 데이터 적용'},
                { id: 47, key: '13', category: '밸류', text: 'POR', value: 'por', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/영업이익, 보통 낮을수록 이익대비 저평가, 최근 4분기 연환산 데이터 적용'},
                { id: 48, key: '14', category: '밸류', text: '분기POR', value: 'qpor', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시가총액/최근분기영업이익*4 , 실적발표일 영업이익*4,  보통 낮을수록 좋음'},
                { id: 49, key: '15', category: '밸류', text: 'NCAV(%)', value: 'ncav', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(유동자산-총부채)*100/시가총액, 높을수록 청산가치 (유동자산-총부채)대비 저평가'},
                { disabled: true, id: 50, key: '16', category: '밸류', text: '예상가괴리율(%)', value: 'forcast_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(예상가*100/현재가)-100, 예상가=(BPS*ROE/회사채3년BBB-), 예상가괴리율이 높을수록 상승여력이 높음, 영업이익<지배순이익의 경우 ROE 계산식에 지배순이익대신 영업이익 사용, 연환산 기준 2년 연속으로 영업이익<순이익의 경우 그대로 순이익으로 사용'},
                { disabled: true, id: 51, key: '17', category: '밸류', text: '상속세괴리율(%)', value: 'inhrit_gap_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(상속주가*100/현재가)-100 상속주가=(최근지배순이익*3/6)+(전년지배순이익*3/6)+(전년지배순이익*2/6)+(전년지배순이익*1/6)*0.6*10+(최근지배송지분*0.4)/발행주식수, 괴리율이 클수록 상승여력이 큼'}
              ],          
            },
            {
              id: 52,
              text: '재무 건전성',
              children: [
                { id: 53, key: '1', category: '안정성', text: '당좌비율(%)', value: 'quick_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '(유동자산-재고자산)*100/유동부채, 높을수록 안정적이며, 100이상 권고'},
                { id: 54, key: '2', category: '안정성', text: '유동비율(%)', value: 'current_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '유동자산*100/유동부채, 높을수록 안정적이며, 200이상이면 안정'},
                { id: 55, key: '3', category: '안정성', text: '부채비율(%)', value: 'debt_rate', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '총부채*100/지배주주소유자기자본, 낮을수록 타인자금을 덜사용하고 사업을 한다고 볼수 있음'},
                { id: 56, key: '4', category: '안정성', text: '차입금비율(%)', value: 'borrowing_rate', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '차입금*100/지배주주소유자기자본, 차입금:이자를 지급해야하는 부채'},
                { id: 57, key: '5', category: '안정성', text: '매출채권회수기간', value: 'revenue_days', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '365(매출액/매출채권, 낮을수록 매출회수기간이 짧음'},
                { id: 58, key: '6', category: '안정성', text: '재고자산회수기간', value: 'inventory_days', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '365(매출액/재고자산, 낮을수록 재고자산 회수기간이 짧음'}
              ],          
            },
            {
              id: 59,
              text: '배당',
              children: [
                { id: 60, key: '1', category: '배당', text: '배당성향(%)', value: 'div', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '총배당금*100/지배주주순이익, 높을수록 이익대비 배당을 많이 줌' },
                { id: 61, key: '2', category: '배당', text: '시가배당율(%)', value: 'dps', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '주당배당금*100/주가, 높을수록 예금대비 투자 매력도가 높음' }
              ],          
            },
            {
              id: 62,
              text: '변동성',
              children: [
                { id: 63, key: '1', category: '변동성', text: '주가변동성', value: 'stock_var', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '1년간 주가 변동성(주가 표준편차), 변동성이 낮은 기업은 주가 모멘텀이 떨어짐'},
                { id: 64, key: '2', category: '변동성', text: '배타(52주)', value: 'beta_52w', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '시장지수대비 민감도 예) KOSPI대비 삼성전자의 메타가 1.5이면 KOSPI 1% 상승시 삼성전자는 1.5% 상승'},
                { id: 65, key: '3', category: '변동성', text: '이익의변동성', value: 'profit_var', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '지배주주순이익의 표준편차/지배주주순이익의 평균 필터로 >0, <1~1.5으로 일회성 이익과 적자기업 제외'}
              ],          
            },
            {
              id: 66,
              text: 'F-Score',
              children: [
                { id: 67, key: '1', category: 'F-Score', text: '지배주주순익>0', value: 'controlling_equit', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 4분기 연환산 지배주주순이익 > 0 이면 1 아니면 0'},
                { id: 68, key: '2', category: 'F-Score', text: '영업활동현금흐름>0', value: 'cashflow', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 4분기 연환산 영업활동현금흐름 > 0 이면 1 아니면 0'},
                { id: 69, key: '3', category: 'F-Score', text: 'ROA증가여부', value: 'roa', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 4분기 ROA가 전년대비 증가했으면 1 아니면 0'},
                { id: 70, key: '4', category: 'F-Score', text: '영업활동현금흐름>순이익', value: 'cashflow_netincome', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 4분기 연환산 영업활동현금흐름 > 최근 4분기 지배주주순이익 이면 1 아니면 0'},
                { id: 71, key: '5', category: 'F-Score', text: '부채비율감소여부', value: 'debt_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 부채비율이 전년동기대비 감소했으면 1, 아니면 0'},
                { id: 72, key: '6', category: 'F-Score', text: '유동비율증가여부', value: 'current_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 유동비율이 전년동기대비 감소했으면 1, 아니면 0'},
                { id: 73, key: '7', category: 'F-Score', text: '신주발행여부', value: 'newshare', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 신주발행을 전년동기대바 안했거나 줄어들면 1, 주식수가 늘었으면 0 (단, 무상증자는 안한것으로 봄)'},
                { id: 74, key: '8', category: 'F-Score', text: '매출총이익율증가여부', value: 'gross_profit', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 4분기 연환산 매출총이익율(GPM)이 전년동기대비 증가했으면 1, 아니면 0'},
                { id: 75, key: '9', category: 'F-Score', text: '총자산회전율증가여부', value: 'total_assets', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '최근 4분기 연환산 총자산회전율(매출액/총자산)이 전년동기대비 증가했으면 1 아니면 0'},
                { id: 76, key: '10', category: 'F-Score', text: 'F스코어점수(9점만점)', value: 'fscore', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '9개 스코어 항목을 모두 합산'}
              ],          
            },
            {
              id: 77,
              text: '성장성',
              children: [
                { id: 78, key: '1', category: '성장성', text: '영업이익/차입금증가율(%)', value: 'opbr_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 영업이익/차입금의 전년동기대비 증가율' },
                { id: 79, key: '2', category: '성장성', text: 'FCFF증가율(%)', value: 'fcff_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 FCFF의 전년동기대비 증가율' },
                { id: 80, key: '3', category: '성장성', text: 'GPA증가율(%)', value: 'gpa_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '연환산 GP/A의 전년동기대비 증가율'},
                { id: 81, key: '4', category: '성장성', text: 'GPM증가율(%)', value: 'gpm_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '연환산 GPM의 전년동기대비 증가율'},
                { id: 82, key: '5', category: '성장성', text: 'ROA증가율(%)', value: 'roa_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '연환산 ROA의 전년동기대비 증가율'},
                { id: 83, key: '6', category: '성장성', text: 'ROE증가율(%)', value: 'roe_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '연환산 ROE의 전년동기대비 증가율'},
                { id: 84, key: '7', category: '성장성', text: '자산증가율(%)', value: 'assets_rate', flag: '↓', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 자산의 전년동기대비 증가율, 저투자기업이 주식수익율이 높음' },
                { id: 85, key: '8', category: '성장성', text: '자본증가율(%)', value: 'capital_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 자본의 전년동기대비 증가율'},
                { id: 86, key: '9', category: '성장성', text: 'BPS증가율(%)', value: 'bps_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '해당분기 BPS의 전년동기대비 증가율'},
                { id: 87, key: '10', category: '성장성', text: 'OPM증가율(%)', value: 'opm_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 OPM의 전년동기대비 증가율' },
                { id: 88, key: '11', category: '성장성', text: 'NPM증가율(%)', value: 'npm_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 NPM의 전년동기대비 증가율' },
                { id: 89, key: '12', category: '성장성', text: '매출증가율(%)', value: 'sales_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 매출의 전년동기대비 증가율' },
                { id: 90, key: '13', category: '성장성', text: '영업이익증가율(%)', value: 'op_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 영업이익의 전년동기대비 증가율' },
                { id: 91, key: '14', category: '성장성', text: '지배주주순이익', value: 'ni_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '연환산 지배주주순이익의 전년동기대비 증가율' },
                { id: 92, key: '15', category: '성장성', text: 'EPS증가율(%)', value: 'eps_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '연환산 EPS의 전년동기대비 증가율'},
                { id: 93, key: '16', category: '성장성', text: '분기 매출액증가율(% YOY)', value: 'sales_yoy_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 매출액의 전년동기대비 YOY 증가율' },
                { id: 94, key: '17', category: '성장성', text: '전분기 매출액증가율(% YOY)', value: 'bsales_yoy_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '전분기 매출액의 전년동기대비 YOY 증가율' },
                { id: 95, key: '18', category: '성장성', text: '분기 매출액증가율(% QOQ)', value: 'sales_qoq_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 매출액의 전년동기대비 QOQ 증가율'  },
                { id: 96, key: '19', category: '성장성', text: '전분기 매출액증가율(% QOQ)', value: 'bsales_qoq_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '전분기 매출액의 전년동기대비 QOQ 증가율' },
                { id: 97, key: '20', category: '성장성', text: '분기 영업이익증가율(% YOY)', value: 'op_yoy_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 영업이익의 전년동기대비 YOY 증가율' },
                { id: 98, key: '21', category: '성장성', text: '전분기 영업이익증가율(% YOY)', value: 'bop_yoy_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '전분기 영업이익의 전년동기대비 YOY 증가율' },
                { id: 99, key: '22', category: '성장성', text: '분기 영업이익증가율(% QOQ)', value: 'op_qoq_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '분기 영업이익의 전년동기대비 QOQ 증가율'  },
                { id: 100, key: '23', category: '성장성', text: '전분기 영업이익증가율(% QOQ)', value: 'bop_qoq_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '전분기 영업이익의 전년동기대비 QOQ 증가율'  },
                { id: 101, key: '24', category: '성장성', text: '분기 지배순이익증가율(% YOY)', value: 'ni_yoy_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment:  '분기 지배주주순이익의 전년동기대비 YOY 증가율'  },
                { id: 102, key: '25', category: '성장성', text: '전분기 지배순이익증가율(% YOY)', value: 'bni_yoy_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '전분기 지배주주순이익의 전년동기대비 YOY 증가율' },
                { id: 103, key: '26', category: '성장성', text: '분기 지배순이익증가율(% QOQ)', value: 'ni_qoq_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '분기 지배주주순이익의 전년동기대비 QOQ 증가율' },
                { id: 104, key: '27', category: '성장성', text: '전분기 지배순이익증가율(% QOQ)', value: 'bni_qoq_rate', flag: '↑', min: -99999999.99, max: 99999999.99, ratio: null, comment: '전분기 지배주주순이익의 전년동기대비 QOQ 증가율' }
              ],          
            },


          ],
        },
      ],

      snackbar: false,
      snackText: null,
      snackColor: null,
      vertical: true,
      }
    },
    
    computed: {
      ...mapState(['debug', 'filters', 'rules']),

      new_filters: function() { 
        return this.copyFilters.concat(this.v_filters);
      },

      new_rules: function() { 
        var c_rules = this.copyRules.concat(this.v_rules);
        // ratio를 선택한 항목수만큼 균등 비율로 Update
        c_rules.map(e => {
            e.ratio = Math.floor(100/c_rules.length);
        });
        return c_rules;
      },
       
      cols1 () {
        const { xs } = this.$vuetify.breakpoint
        return xs ? 12 : 6
      },        
    },

    created() {
      // 초기화
      this.setFilter({'filters': []});
      this.setRule({'rules': []});     
    },

    methods: {
      ...mapMutations(['setRule', 'setFilter']),

      saveFilter() {
        this.setFilter({'filters': this.new_filters});
      },

      saveRule() {
        var sumRatio = 100
        sumRatio = this.new_rules.map(item => parseInt(item.ratio)).reduce((prev, curr) => prev + curr, 0);
        if (sumRatio !== 100) {
            this.snackbar = true
            this.snackColor = 'red lighten-1'
            this.snackText = '비중 합계는 100%로 설정하세요.'
        } else {      
          this.setRule({'rules': this.new_rules});
        }
      },

      putFilter() {
        this.setFilter({'filters': this.new_filters});
      },

      putRule() {
        this.setRule({'rules': this.new_rules});
      },

      deleteFilterItem (item) {
        this.deletedIndex = this.v_filters.indexOf(item);
        this.v_filters.splice(this.deletedIndex, 1);
        this.setFilter({'filters': this.new_filters});
      },

      deleteRuleItem (item) {
        this.deletedIndex = this.v_rules.indexOf(item);
        this.v_rules.splice(this.deletedIndex, 1);
        this.setRule({'rules': this.new_rules});
      },
    },

  }
</script>
