<template>
<div class="test"> 
  <span class="text-h5 text--primary d-flex pa-2 font-weight-bold"> 국내주식 백테스트
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-icon class="pl-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
      </template>
      <BacktestHelp
        :hs00 = "true"
      />
    </v-tooltip>
  </span>
  <br>
  <v-container>
    <v-row justify="end">
      <v-col class="d-flex justify-end">
        <v-btn 
          text
          color="primary"
          small 
          class="font-weight-bold" 
          @click="ruleCopyDialog"
        > 
          <v-icon left> mdi-plus </v-icon> 기존 전략 복사</v-btn>
      </v-col>
    </v-row>
  </v-container>
  
  <!-- 복사한 퀀트제목 -->
  <span class="pl-10 d-flex justify-center text-subtitle-1">
    {{ v_quant_title }}
  </span>

  <!-- 기본필터 -->
  <FilterFormV02/>

  <!-- 지표필터-->
  <FilterTreeV02 
    :copyFilters="v_filters"
    :copyRules="v_rules"
  />
  <!-- <FilterSelectV02 /> -->

   <!-- 검색/백테스트 버튼 -->
  <br>
  <v-row justify="space-around">
      <v-btn
        color="primary"
        dark
        large
        @click="search"
      >종목검색</v-btn>
      <v-btn
        class="pl-6"
        color="primary"
        dark
        large
        @click="test"
      >백테스트</v-btn>
    </v-row>

  <!-- 기존전략 복사 다이날로그 -->
  <v-dialog
    v-model="dialogCopy"
    max-width="400" 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="text-h5">기존 전략 복사</v-card-title>
      <v-card-text>
      <v-container>                        
        <v-row>
          <v-col cols="12">
            <v-radio-group  @change="ruleCopyDialog" v-model="who" row>   
              <v-radio label="나의 전략" value="my"></v-radio>
              <v-radio label="전문가 전략" value="expert"></v-radio>
            </v-radio-group> 
          </v-col>
          <v-col cols="12">
            <v-select
              :items="ruleList"
              :menu-props="{ top: true, offsetY: true }"
              v-model="selectedRule"
              label="전략을 선택하세요."
            ></v-select>                        
          </v-col>
        </v-row>
      </v-container>
      </v-card-text>
      <v-divider></v-divider>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer><v-spacer></v-spacer>
            <v-btn depressed outlined @click="dialogCopy = false">닫기</v-btn>
            <v-btn color="primary" @click="copyRule()">복사</v-btn>
          </v-card-actions>
    </v-card> 
  </v-dialog>

  <!-- 백테스트 다이알로그 -->
  <v-dialog
    v-model="dialogTest"
    width="auto" 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
      <v-card>
          <v-card-title class="text-h5">백테스트</v-card-title>
          <v-card-text>
          <v-container>                        
              <v-row>
              <v-col cols="12">
                  <v-text-field
                  label="제목" required                            
                  v-model="title"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                  <v-text-field
                    label="초기투자금(만원)" required                            
                    v-model="amount_selected"
                    hint="투자금 수정 가능합니다."
                    persistent-hint
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                  <v-text-field
                    label="편입종목수" required                            
                    v-model="stock_selected"
                    hint="종목수 수정 가능합니다."
                    persistent-hint
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                  <v-text-field
                    label="테스트기간(년)" required                            
                    v-model="time_selected"
                    hint="테스트 데이터는 2015년 4월부터 가능합니다."
                    persistent-hint
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                  <v-select
                  :items="tradeTerms"
                  :menu-props="{ top: true, offsetY: true }"
                  label="리밸런싱주기*" required
                  v-model="term_selected"
                  ></v-select>                        
              </v-col>
              </v-row>       
              <div class="d-flex text-justify">
                  <small>
                      <div>*리밸런싱주기</div>
                      <div>월  : 매월말   </div> 
                      <div>분기: 3월말(4분기), 5월말(1분기), 8월말(2분기), 11월말 (3분기) </div>
                      <div>반기: 5월말(1분기), 11월말 (3분기) </div>
                      <div>연간: 3월말(4분기) </div>
                      <div>시즌제: 올해 매수싯점(월말) ~ 다음해 매도싯점(월말) </div>
                      <!-- <v-list dense>
                      <v-subheader>*리밸런싱주기</v-subheader>
                      <v-list-item>월  : 매월말   </v-list-item> 
                      <v-list-item>분기: 3월말(4분기), 5월말(1분기), 8월말(2분기), 11월말 (3분기) </v-list-item>
                      <v-list-item>반기: 5월말(1분기), 11월말 (3분기) </v-list-item>
                      <v-list-item>연간: 3월말(4분기) </v-list-item>
                      <v-list-item>시즌제: 올해 매수싯점(월말) ~ 다음해 매도싯점(월말) </v-list-item>
                      </v-list> -->
                  </small>          
              </div>                 
          </v-container>
          </v-card-text>          
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
              <v-spacer></v-spacer><v-spacer></v-spacer>
              <v-btn depressed outlined @click="dialogTest = false">닫기</v-btn>
              <v-btn color="primary" @click="backtest_start">시작</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

  <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>

</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import FilterFormV02 from '../components/FilterFormV02.vue';  
  import FilterTreeV02 from '../components/FilterTreeV02.vue';
  import BacktestHelp from '../components/BacktestHelp.vue';  
  // import FilterSelectV02 from '../components/FilterSelectV02.vue';  

  export default {
    name: 'QuantFilterV02',
    components: { FilterFormV02, FilterTreeV02, BacktestHelp },
    //components: { FilterFormV02, FilterSelectV02 },
    data: function () {
      return {
        dialogTest: false,
        dialogCopy: false,
        title: null,
        tradeTerms: ['월','분기','반기','년','시즌'],
        amount_selected: 1000,
        stock_selected: 10,
        time_selected: 5,
        term_selected: null,
        test_text: {},
        who: 'my',
        selectedRule: null,
        quantList: [],
        ruleList: [],

        v_quant_title: null,
        v_market: 'ANY',
        v_market_cap: 100,
        v_sector: ['전체'],
        v_filters: [],
        v_rules: [],

        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,

      }
    },

    computed: {
      ...mapState([ 'isLogin', 'debug', 'user', 'market', 'market_cap', 'sector', 'filters', 'rules'])
    },

    created() {
      if (!this.isLogin) this.$router.push('/');
    },

    methods: {
      ...mapMutations(['setMarket', 'setFilter', 'setRule', 'setRoute', 'setTest']),

      search() {
        if (this.rules.length == 0) {
          this.snackbar = true;
          this.snackColor = 'red'
          this.snackText = '매수전략 지표을 선택 주세요.'
        } else {
          this.$router.push('/search');
        }
      },

      test() {
        if (this.rules.length == 0) {
          this.snackbar = true;
          this.snackColor = 'red'
          this.snackText = '매수전략 지표을 선택 주세요.'
        } else {
          this.dialogTest = true;
        }
      },

      backtest_start() {
        if (this.title == null || this.amount_selected == null || this.stock_selected == null || 
            this.time_selected == null || this.term_selected == null) {
          this.snackbar = true;
          this.snackColor = 'red'
          this.snackText = '제목, 초기투자금, 편입종목수, 테스트기간, 리밸런싱주기를 입력해 주세요.'
        } else {       
          this.setRoute({'route': 'form'});
          this.test_text['title'] = this.title;
          this.test_text['amount'] = this.amount_selected;
          this.test_text['stocks'] = this.stock_selected;
          this.test_text['time'] = this.time_selected;
          this.test_text['term'] = this.term_selected;
          this.setTest(this.test_text);
          this.$router.push('/backtest');
          this.dialogTest = false;
        }
      },

      ruleCopyDialog() {
        this.dialogCopy = true;
        this.ruleList = [];
        var vm = this;
        
        const req_data = {user: this.user}
        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
        axios.post('/api/quant/copy/'+this.who+'/', req_data, {headers})
            .then(function(res) {
                vm.quantList = res.data;
                vm.quantList.forEach(element => {
                  vm.ruleList.push({'text': element['title'], 'value': element['quant_id']});
                });                    
            })
            .catch(function (err) {  
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;            
            });
      },

      copyRule() {
        var index = this.quantList.findIndex(i => i.quant_id == this.selectedRule); 
        this.v_quant_title = '퀀트전략: ' + this.quantList[index]['title'];
        this.v_market = this.quantList[index]['market'];
        this.v_market_cap = this.quantList[index]['market_cap'];
        this.v_sector = this.quantList[index]['sector'];
        this.v_filters = this.quantList[index]['filters'];
        this.v_rules = this.quantList[index]['rules'];
        this.setMarket({'market': this.v_market, 'market_cap': this.v_market_cap, 'sector': this.v_sector});
        this.setFilter({'filters': this.v_filters});
        this.setRule({'rules': this.v_rules});
        this.dialogCopy = false;        
      },
    
    },
  }
</script>

<style>
.test{
  padding: 12px;
  margin-top: 10px;
}
</style>